import { IntegrationService } from '@/services/integrations/integrationService';

import GET_MAILCHIMP_DATA from '@/graphql/GetMailChimpGroupsAndFields.gql';

class MailChimpIntegrationService extends IntegrationService {
  async fetchMailChimpGroupsAndFields(integrationType, integrationId, listId) {
    try {
      const result = await this.$apollo.query({
        query: GET_MAILCHIMP_DATA,
        variables: {
          integrationType,
          integrationId,
          listId,
        },
      });

      return result?.data;
    } catch (e) {
      console.error('Integration data query failed', e.message);
      throw Error(e.message);
    }
  }
}

export { MailChimpIntegrationService };
